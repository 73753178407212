<template>
    <div>
        <div class="page-group-box">
            <div class="page-group-title clearfix">
                <div class="fl">回报项 <span v-if="!disabled">按住鼠标,可拖动排序</span></div>
                <div class="fr"><el-button type="primary" size="mini" @click="add">新增回报项</el-button></div>
            </div>
            <div class="page-list-box">
                <el-table
                    :data="formData.raiseTicketPrices"
                    style="width: 100%"
                    size="small"
                    header-row-class-name="page-list-table"
                    row-key="ticketName"
                    class="sortable MT20"
                >
                    <!-- <el-table-column prop="sort" label="序号"></el-table-column> -->
                    <el-table-column prop="ticketName" label="名称" min-width="200" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="priceFormat" label="价格"></el-table-column>
                    <el-table-column prop="deliveryType" label="发货方式">
                        <template slot-scope="scope">
                            <span v-if="scope.row.deliveryType == 1">网络发送</span>
                            <span v-if="scope.row.deliveryType == 2">国内包邮</span>
                            <span v-if="scope.row.deliveryType == 3">无需发货</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="isMultipleSupport" label="账号限购">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isMultipleSupport <= 0">不限购</span>
                            <span v-else>{{scope.row.isMultipleSupport}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="totalStock" label="总库存(次)"></el-table-column>
                    <template v-if="disabled">
                        <el-table-column prop="sellStock" label="已销售(次)"></el-table-column>
                        <el-table-column prop="occupyStock" label="待支付(次)"></el-table-column>
                        <el-table-column prop="surplusStock" label="剩余(次)"></el-table-column>
                    </template>
                    <el-table-column prop="startTime" label="开放时间" min-width="147"></el-table-column>
                    <el-table-column prop="endTime" label="关闭时间" min-width="147"></el-table-column>
                    <el-table-column prop="redoundTime" label="预计回报时间" min-width="147"></el-table-column>
                    <el-table-column fixed="right" label="操作" width="80">
                        <template slot-scope="scope">
                            <span
                                class="btn color-theme"
                                @click="edit(scope.row,scope.$index)"
                                >管理</span
                            >
                            <el-button
                                v-if="!scope.row.id"
                                type="text"
                                size="small"
                                @click="delTickPrices(scope.row,scope.$index)"
                                >删除</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <TicketPricesEdit 
            ref="ricketPricesEdit" 
            :index="index" 
            :disabled="disabled" 
            @onUpdate="onUpdateTicketPrice"
            :ticketPrices="formData.raiseTicketPrices" 
        ></TicketPricesEdit>
    </div>
</template>

<script>
import TicketPricesEdit from './raise-ticketPrices-edit';
import Sortable from "sortablejs";

export default {
    props:{
       formData: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    components:{
        TicketPricesEdit
    },
    data(){
        return{
            index: 0
        }
    },
    mounted(){
        if( !this.disabled ){
            this.drag(); 
        }
        this.updateTickPricesSort();
    },
    methods:{
        edit(item,index){
            this.index = index;
            this.$refs.ricketPricesEdit.show(item);
        },
        add(){
            this.index = this.formData.raiseTicketPrices.length;
            this.$refs.ricketPricesEdit.show();
        },
        //删除票品事件
        delTickPrices(item,index){
            this.$confirm('是否删除当前回报项?删除后数据不可恢复', '提示', {
                type: 'warning',
                center: true,
                closeOnClickModal: false
            }).then(() => {
                this.formData.raiseTicketPrices = this.formData.raiseTicketPrices.filter( (v,i) => i !== index );
                this.updateTickPricesSort();
            }).catch(() => {})
        },
        //更新票品排序
        updateTickPricesSort(){
            this.formData.raiseTicketPrices.map( (item,index) => {
                item.sort = index;
            })
        },
        //更新回报项
        onUpdateTicketPrice(data){
            const obj = data;
            this.updateTickPricesSort();
            this.$set(this.formData.raiseTicketPrices,obj.sort,{...obj});
            this.drag();
        },
        //票品拖拽排序
        drag(){
            this.$nextTick(() => {
                const el = document.querySelectorAll('.sortable> .el-table__body-wrapper > table > tbody')[0];
                Sortable.create(el, {
                    disabled: false, // 拖拽不可用? false 启用
                    ghostClass: 'sortable-ghost', //拖拽样式
                    animation: 150, // 拖拽延时，效果更好看
                    onEnd: (e) => { // 拖拽结束时的触发
                        let arr = this.formData.raiseTicketPrices; // 获取表数据
                        arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]); // 数据处理，获取最新的表格数据
                        this.$nextTick(function () {
                            this.formData.raiseTicketPrices = [...arr];
                            this.updateTickPricesSort();
                            // console.log(item.ticketPrices)
                        })
                    },
                });
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.page-list-box{
    padding-bottom: 20px;
    margin-bottom: 0;
    .btn{
        cursor: pointer;
        margin-right: 8px;
    }
}
</style>