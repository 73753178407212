<template>
    <div>
        <el-drawer
            :title="!formDatas.id ? '新增回报项' : '编辑回报项'"
            :visible.sync="drawerShow"
            :wrapperClosable="false"
            :destroy-on-close="true"
            append-to-body
            size="auto"
            @close="close"
        >
            <div class="drawer-content">
                <el-form
                    :model="formDatas"
                    :rules="rules"
                    ref="formDatas"
                    label-position="top"
                    :disabled="disabled"
                >
                    <el-form-item prop="ticketName" label="名称:">
                        <el-input
                            v-model="formDatas.ticketName"
                            placeholder="请输入名称"
                            :maxlength="30"
                            size="medium"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="datetimerange" label="开放关闭时间:">
                        <el-date-picker
                            v-model="formDatas.datetimerange"
                            type="datetimerange"
                            start-placeholder="开放时间"
                            end-placeholder="关闭时间"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            size="medium"
                            :default-time="['00:00:00', '23:59:59']"
                            unlink-panels
                        ></el-date-picker>
                    </el-form-item>
                    <el-form-item prop="redoundTime">
                        <span slot="label">
                            预计回报时间: <small>对于无须发货的回报项,预计回报时间可以任意填写</small>
                        </span>
                        <el-date-picker
                            v-model="formDatas.redoundTime"
                            type="datetime"
                            placeholder="请选择预计回报时间"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            size="medium">
                        </el-date-picker>
                    </el-form-item>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item prop="price" label="价格(元):">
                                <el-input-number style="width:100%" v-model="formDatas.price" :precision="2" controls-position="right" :min="0.00" :max="99999999.99" size="medium" placeholder="请输入价格"></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="isMultipleSupport">
                                <span slot="label">
                                    限购: <small>0为不限购</small>
                                </span>
                                <el-input-number style="width:100%" v-model="formDatas.isMultipleSupport" :precision="0" controls-position="right" :min="0" :max="99999999" size="medium" placeholder="请输入用户多次支持限制"></el-input-number>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item prop="totalStock">
                                <span slot="label">
                                    库存: <!--<small>0为无限库存</small>-->
                                </span>
                                <el-input-number style="width:100%" v-model="formDatas.totalStock" :precision="0" controls-position="right" :min="0" :max="99999999" size="medium" placeholder="请输入库存数"></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="deliveryType" label="发货方式:">
                                <el-select v-model="formDatas.deliveryType" size="medium">
                                    <el-option :value="1" label="网络发送"></el-option>
                                    <el-option :value="2" label="国内包邮"></el-option>
                                    <el-option :value="3" label="无需发货"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item prop="instruction" label="回报说明:">
                        <el-input
                            v-model="formDatas.instruction"
                            placeholder="请输入回报说明"
                            :maxlength="500"
                            type="textarea"
                            :rows="4"
                            show-word-limit
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="imageUrls">
                        <span slot="label">图片: <small>支持格式为JPG、PNG,不大于10M,最多上传9张</small></span>
                        <el-upload
                            action=""
                            list-type="picture-card"
                            accept="image/png, image/jpeg"
                            :file-list="fileList"
                            :http-request="uploadImage"
                            :on-remove="handleRemove"
                            :before-upload="beforeUpload"
                            :disabled="uploadLoading"
                            :limit="9"
                            :on-exceed="handleExceed">
                            <el-progress v-if="progress && progress < 100" :width="148" type="circle" :percentage="progress"></el-progress>
                            <i v-else class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                </el-form>
            </div>
            <div v-if="!disabled" class="drawer-footer">
                <el-button @click="close">取消</el-button>
                <el-button type="primary" @click="submitForm()" :loading="btnLoading"
                    >确定</el-button
                >
            </div>
        </el-drawer>
    </div>
</template>

<script>
import {uploadImg} from '@/assets/js/commonApi.js';

const defaultFormData = {
    deliveryType: 3,
    startTime:'',
    endTime:'',
    datetimerange:[],
    imageUrls:[],
    instruction:'',
    isMultipleSupport: 0,
    priceFormat:'',
    price:undefined,
    redoundTime:'',
    ticketName:'',
    totalStock:0,
    sort:0,
}
export default {
    props:{
        disabled: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            default: 0,
        },
        ticketPrices: {
            type: Array,
            default: () => []
        },
    },
    data(){
        return{
            drawerShow: false,
            btnLoading: false,
            formDatas: {
                ...defaultFormData,
            },
            rules: {
                ticketName: [{ required: true, message: "请输入名称" }],
                datetimerange: [{ required: true, message: "请选择开放关闭时间" }],
                redoundTime: [{ required: true, message: "请选择预计回报时间" }],
                price: [{ required: true, message: "请输入价格",trigger:'blur' }],
                isMultipleSupport:[{ required: true, message: "请输入用户多次支持限制" }],
                totalStock:[{ required: true, message: "请输入库存数量" }],
            },
            fileList:[],
            fileListUrl:[],
            uploadLoading: false,
            progress:null,
            itemData: null
        }
    },
    methods:{
        close() {
            this.fileList = [];
            this.fileListUrl = [];
            this.formDatas = { ...defaultFormData };
            this.$refs.formDatas.resetFields();
            this.drawerShow = false;
        },
        show(itemData) {
            this.itemData = itemData || null;
            this.formDatas = {
                ...defaultFormData,
                ...itemData,
                datetimerange: itemData && itemData.startTime ? [itemData.startTime,itemData.endTime] : [],
                price: itemData && itemData.priceFormat ? itemData.priceFormat ||  itemData.price : undefined,
            }
            this.fileListUrl =  this.formDatas.imageUrl ? this.formDatas.imageUrl.split(',') : []; 
            this.formDatas.imageUrls.map(item => {
                this.fileList.push({
                    url: item
                })
            })
            this.drawerShow = true;
        },
        handleExceed(files, fileList) {
            this.$message.warning('最多上传9张图片');
        },
        handleRemove(file, fileList) {
            const _index = this.fileList.findIndex(v => v === file);

            this.fileListUrl = this.fileListUrl.filter( (v,i) => i !== _index);

            //this.formDatas.imgList = this.formDatas.imgList.filter( (v,i) => i !== _index);

            this.fileList = fileList; //this.fileList.filter( v => v !== file)
        },
        beforeUpload(file){
            this.fileList = this.fileList.filter( v => v !== file)
        },
        async uploadImage(req){
            const {
                file,
                file: { type, size },
            } = req;

            //格式检查
            const formatVerified =
                type === "image/jpeg" || file.type === "image/png";
            if (!formatVerified) {
                this.$message.error("图片格式错误,请重新上传");
                return;
            }
            //大小检查
            const bytesVerified = size / 1024 / 1024 < 10;
            if (!bytesVerified) {
                this.$message.error("图片尺寸错误,请重新上传");
                return;
            }

            // 上传图片
            const formData = new FormData();
            formData.append("file", file);
             
            this.uploadLoading = true;
            const res = await uploadImg(formData,ev => {
                this.progress = Math.floor((ev.loaded / ev.total) * 100);
            }).finally(() => {
                this.uploadLoading = false;
            });

            const {state,result,msg} = res.data;
            if(state == 1){
                this.fileList.push({
                    url:result.urlFull
                });
                //this.formDatas.imgList.push(result.urlFull);
                this.fileListUrl.push(result.url);
            }else{
                this.$message.error( msg || '上传失败');
            }
        },
        //表单提交
        submitForm() {
            this.$refs["formDatas"].validate((valid) => {
                
                if (valid) {

                    const sameName = this.ticketPrices.find( v => v.ticketName == this.formDatas.ticketName);
                    if( sameName && !this.itemData ){
                        return this.$message.error('该回报项名称已被占用,请重新输入');
                    } 

                    this.formDatas.imageUrl = this.fileListUrl + '';
                    this.formDatas.imageUrls = this.fileList.map( v => v.url );
                    this.formDatas.sort = this.index;
                    this.formDatas.startTime = this.formDatas.datetimerange[0];
                    this.formDatas.endTime = this.formDatas.datetimerange[1];
                    this.formDatas.priceFormat = parseFloat(this.formDatas.price).toFixed(2) || 0.00;
                    
                    this.$emit('onUpdate',{...this.formDatas});
                    this.close();
                } else {
                    return false;
                }
            });
        },
    }
}
</script>