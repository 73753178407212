<template>
    <div>
        <div class="page-top-box">
            <div class="page-title-box">
                <div class="go-back">
                    <el-button type="text" icon="el-icon-back" @click="goBack">返回</el-button>|
                </div>
                {{ $route.meta.title }}
            </div>
        </div>
        <step-status :active="2"></step-status>
        <RaiseTicketPricesList :formData.sync="formData" v-loading="dataLoading"></RaiseTicketPricesList>
        <div class="page-group-box MT10">
            <el-button @click="goBack">上一步</el-button>
            <el-button @click="draftForm" :loading="btnLoading">存为草稿</el-button>
            <el-button
                type="primary"
                @click="submitForm"
                :loading="btnLoading"
                :disabled="formData.raiseTicketPrices.length < 1"
                >提交审核</el-button
            >
        </div>
    </div>
</template>
<script>
import StepStatus from "./components/step-status";
import RaiseTicketPricesList from './components/raise-ticketPrices-list';

export default {
    props: {
        id: {
            type: [Number, String],
            default: null,
        },
    },
    components:{
        StepStatus,
        RaiseTicketPricesList
    },
    data(){
        return{
            redirect: this.$route.query.redirect || `/manage/raise/draft`,
            publishType: this.$route.query.publishType || 1, //1、新建，2、编辑
            dataLoading:false,
            btnLoading: false,
            formData:{
                raiseTicketPrices:[]
            }
        }
    },
    created(){
        if( this.publishType == 2 ){
            this.getDataDetail();
        }
    },
    methods:{
        //获取详情
        getDataDetail() {
            this.dataLoading = true;
            this.$request({
                url: "/raise/info",
                params: { id: parseInt(this.id) },
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1) {
                        this.formData = result;
                    } else {
                        this.$message.error(msg || "获取信息失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("获取信息失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },
        //返回
        async goBack() {
            const res = await this.publish(1).catch(() => {
                this.$message.error(msg || "操作失败,请重试");
            });
            const { state, msg } = res.data;
            if (state == 1) {
                this.$router.push({
                    path: `/manage/raise/edit/step1/${this.id}`,
                    query:{
                        redirect: this.redirect
                    }
                });
            } else {
                this.$message.error(msg || "操作失败,请重试");
            }
            
        },
        //存草稿
        draftForm(){
            this.$confirm('存为草稿后，您可以在【众筹草稿】页面找到当前众筹', '提示', {
                type: 'warning',
                center: true,
                closeOnClickModal: false
            }).then(async () => {
                const res = await this.publish(1);
                const { state, msg } = res.data;
                if (state == 1) {
                    this.$message.success("保存成功");
                     this.getDataDetail();
                } else {
                    this.$message.error(msg || "操作失败,请重试");
                }
            }).catch(() => {})
        },
        // 提交审核
        submitForm(){
            if(this.formData.raiseTicketPrices.length < 1){
                return this.$message.error("请添加回报项");
            }

            this.$confirm('提交审核后，将暂时无法编辑众筹项目，请耐心等待秀动官方进行审核。您可以在【众筹列表】看到等待审核的众筹。当然您也可以暂时不提交审核，选择【存为草稿】后可以在【众筹草稿】看到当前众筹', '提示', {
                type: 'warning',
                center: true,
                closeOnClickModal: false
            }).then(async () => {
                const res = await this.publish(2);
                const { state, msg } = res.data;
                if (state == 1) {
                    this.$router.push({
                        path: `/manage/raise/edit/finish/${this.id}`,
                        query: {
                            redirect: this.$router.currentRoute.fullPath,
                        },
                    });
                } else {
                    this.$message.error(msg || "操作失败,请重试");
                }
            }).catch(() => {})
        },
        publish(opType){
            this.btnLoading = true;
            return this.$request({
                url: "/raise/publish/price",
                data: { 
                    id: parseInt(this.id),
                    opType: opType,
                    raiseTicketPrices: this.formData.raiseTicketPrices
                },
                method: "post",
            })
                .catch(() => {
                    this.$message.error("操作失败,请重试");
                })
                .finally(() => {
                    this.btnLoading = false;
                });
        }
    }
}
</script>